import React, { useState, useEffect, useRef, useContext } from 'react';
import classNames from 'classnames/bind';
import { shape, arrayOf } from 'prop-types';
import {
  QueryProvider, extend, string, params, useDataModel
} from '@thd-nucleus/data-sources';
import { useStore, ExperienceContext } from '@thd-nucleus/experience-context';
import { Drawer, DrawerItem, DrawerHeader } from '@thd-olt-component-react/drawer';
import {
  ProductPod,
  ProductImage, PodSection, PodSpacer,
  ProductRemove
} from '@thd-olt-component-react/product-pod';
import QRScanner from 'qr-scanner';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { Button } from '@one-thd/sui-atomic-components';
import { ConfirmedFilled, WarningFilled } from '@one-thd/sui-icons';
import { Barcode } from '../digital-ticket-barcode/DigitalTicketBarcode';
import { ScanAnotherTicketDrawer } from '../scan-another-ticket-drawer/ScanAnotherTicketDrawer';
import styles from './digital-ticket-drawer.module.scss';
import {
  getLocalStoreQuantity,
  updateScanRegionHighlightStyles
} from '../digital-ticket-utils/DigitalTicketUtils';
import { dataModel, ProductModel } from '../DigitalTicketDataModel';

const cx = classNames.bind(styles);

const Wrapper = ({ children }) => {
  return children;
};

Wrapper.dataModel = {
  product: params({ itemId: string().isRequired(), dataSource: string() }).shape(ProductModel)
};

export const DigitalTicketDrawer = ({ digitalTickets }) => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [tickets, setTickets] = useState(digitalTickets);
  const [isScanTicketDrawerOpen, setIsScanTicketDrawerOpen] = useState(false);
  const [scanner, setScanner] = useState(null);
  const [deleted, setDeleted] = useState();
  const scanButtonRef = useRef(null);
  const videoRef = useRef(null);
  const { isConsumerApp } = useContext(ExperienceContext);
  const parentId = digitalTickets?.[0]?.identifiers?.parentId || '';
  const isSuperSku = digitalTickets?.[0]?.identifiers?.isSuperSku;
  const localStorageReady = typeof window !== 'undefined' && window.localStorage;

  const { storeId, isLocalized, storeZip } = useStore();

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('digital-ticket.ready');
    LIFE_CYCLE_EVENT_BUS.on('NATIVE_APP.scanned-item', ({ scannedInfo }) => {
      window.location = scannedInfo;
    });
  }, []);

  useEffect(() => {
    if (digitalTickets?.length) {
      setTickets(digitalTickets);
    }
  }, [digitalTickets]);

  const setInLocalStorage = (key, value) => {
    if (localStorageReady) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  };

  const { data: metadata, loading: loadingMetadata } = useDataModel('metadata', {
    variables: {
      parentId
    },
    ssr: false
  });

  let superSkuIds = metadata?.metadata?.childItemsLookup?.map((item) => item.itemId);

  if (parentId && isSuperSku
    && superSkuIds && digitalTickets[0].itemId !== deleted) {
    setInLocalStorage('ssku', superSkuIds);
  } else {
    setInLocalStorage('ssku', []);
  }

  const barcodeOptions = {
    displayValue: false,
    width: 3,
    height: 40
  };

  const LIMITED_STOCK_MESSAGE = 'Limited Stock';

  const deleteTicket = (ticket) => {
    let updatedTickets = tickets.filter((obj) => ticket !== obj.itemId);
    let scannedTickets = localStorageReady && localStorage.getItem('digitalTickets')
      ? JSON.parse(localStorage.getItem('digitalTickets')) : [];
    scannedTickets = scannedTickets.length && scannedTickets[0] !== null ? scannedTickets
      .filter((obj) => ticket !== obj.itemId) : [];
    setInLocalStorage('digitalTickets', scannedTickets);
    setTickets(updatedTickets);
    setDrawerOpen(updatedTickets.length > 0);
    setDeleted(ticket);
  };

  const scanAnotherItem = () => {

    const qrScanner = new QRScanner(
      videoRef?.current,
      (result) => {
        qrScanner.stop();
        window.location = result.data;
      },
      { highlightScanRegion: true, returnDetailedScanResult: true }
    );

    /* istanbul ignore else */
    if (qrScanner) {
      qrScanner.start();
      setScanner(qrScanner);
    }
  };

  const openQRScanner = (event) => {
    event.preventDefault();
    LIFE_CYCLE_EVENT_BUS.trigger('digital-ticket.scan-another-item-click');

    if (!(isConsumerApp && window.navigator?.userAgent.match(/\bTHDConsumer\b.*\bAndroid\b/))) {
      scanAnotherItem();
      updateScanRegionHighlightStyles(scanButtonRef, videoRef);
      setIsScanTicketDrawerOpen(true);
    }
  };

  if (!tickets?.length) {
    return null;
  }

  const handleScanAnotherTicketDrawerClose = () => {
    if (scanner) {
      scanner.stop();
    }
    setIsScanTicketDrawerOpen(false);
  };

  const drawerTitle = `Digital Tickets (${tickets?.length})`;
  return (drawerOpen && tickets?.length && (
    <Drawer
      position="bottom"
      open
      onClose={() => setDrawerOpen(!drawerOpen)}
      initialItem="digital-ticket"
      enableScroll
      hideMask
      maxSize={380}
      persistOnOrientationChange
    >
      <DrawerItem name="digital-ticket" isFlex>
        <DrawerHeader title={drawerTitle} elevation={1}>
          <div className={cx('digital-ticket__instructions-wrapper')}>
            <p className={cx('digital-ticket__instructions')}>
              Keep tickets open to show to cashier at checkout.
              {'\n'}Max 16 tickets - scan again to show available tickets.
            </p>
          </div>
        </DrawerHeader>
        {tickets.length > 0 && isLocalized && (
          <QueryProvider
            key="digital-tickets"
            cacheKey="digital-tickets"
            dataSource="digital-ticket"
            defaultVariables={{ storeId, zipCode: storeZip }}
          >
            {tickets.length > 0 && tickets.map((item, index) => {
              const { identifiers, fulfillment, itemId } = item || {};
              const { storeSkuNumber = '' } = identifiers || {};
              const { fulfillmentOptions = [] } = fulfillment || {};
              const quantity = getLocalStoreQuantity(fulfillmentOptions);
              return (
                <div key={itemId}>
                  <div className={cx('digital-ticket__body', 'sui-pl-3', 'sui-pr-3')}>
                    <Wrapper>
                      <ProductPod
                        itemId={itemId}
                        storeId={storeId}
                        analyticsData={{ parent: 'digital-ticket-drawer', position: index + 1 }}
                        render={(pod) => (
                          <>
                            <PodSection noPadding>
                              <div className="sui-flex sui-grid sui-grid-cols-6">
                                <div className="sui-col-span-2 sui-p-2 sui-m-2px">
                                  <ProductImage
                                    itemId={pod.itemId}
                                  />
                                </div>
                                <div className="sui-col-span-4 sui-p-2 sui-m-2px">
                                  <PodSection noPadding columnAlign>
                                    <PodSpacer padding={['TOP', 'BOTTOM']}>
                                      <div className="sui-flex">
                                        <ProductHeader
                                          brand="inline"
                                          itemId={pod.itemId}
                                          brandTitleMaxLine={2}
                                          disableShopThisCollection
                                        />
                                      </div>
                                      <div
                                        className={cx('digital-ticket__inventory-message')}
                                        data-testid="inventory"
                                      >
                                        {quantity ? (
                                          <>
                                            <ConfirmedFilled size="small" color="success" />
                                            <span className={cx('inventory-text')}>
                                              {quantity} in stock
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <WarningFilled size="small" color="warning" />
                                            <span className={cx('inventory-text')}>{LIMITED_STOCK_MESSAGE }</span>
                                          </>
                                        )}
                                      </div>
                                      <div className="sui-flex sui-grid sui-grid-cols-6">
                                        <div className="sui-col-span-3">
                                          <div className={cx('digital-ticket__store-sku-text')}>
                                            <span className={cx('digital-ticket__store-sku-number')}>
                                              {storeSkuNumber || 'Loading...'}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="sui-col-span-3">
                                          <div className={cx('digital-ticket__product-remove')}>
                                            <ProductRemove
                                              text="Delete Ticket"
                                              link
                                              itemId={item.itemId}
                                              onRemove={() => { deleteTicket(item.itemId); }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </PodSpacer>
                                  </PodSection>
                                </div>
                              </div>
                            </PodSection>
                            <Barcode text={storeSkuNumber} options={barcodeOptions} />
                            <hr />
                          </>
                        )}
                      />
                    </Wrapper>
                  </div>
                </div>
              );
            })}
          </QueryProvider>
        )}
        <div className={cx('digital-ticket__footer', 'sui-bg-primary', 'sui-p-3', 'sui-font-bold')}>
          <Button variant="primary" fullWidth onClick={openQRScanner}>Scan Another Item</Button>
        </div>
      </DrawerItem>
      <ScanAnotherTicketDrawer
        open={isScanTicketDrawerOpen}
        videoRef={videoRef}
        scanButtonRef={scanButtonRef}
        onClose={handleScanAnotherTicketDrawerClose}
      />
    </Drawer>
  ));
};

DigitalTicketDrawer.dataModel = extend(dataModel);

DigitalTicketDrawer.propTypes = {
  digitalTickets: arrayOf(shape({})).isRequired
};

DigitalTicketDrawer.displayName = 'DigitalTicketDrawer';
