export const getLocalStoreQuantity = (fulfillmentOpts) => {
  const pickupService = (fulfillmentOpts || []).find((option) => option?.type === 'pickup');
  const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');
  const localStore = (bopisFulfillment?.locations || []).find((location) => location.isAnchor);
  return localStore?.inventory?.quantity;
};

export const updateScanRegionHighlightStyles = (scanButtonRef = {}, videoRef) => {
  if (!scanButtonRef.current) return;
  const element = scanButtonRef.current.querySelector('.scan-region-highlight');
  const svgElement = scanButtonRef.current.querySelector('.scan-region-highlight-svg');
  const videoElement = videoRef.current.parentNode.parentNode;
  if (svgElement) {
    svgElement.style.display = 'none';
  }
  if (element) {
    const clonedElement = element.cloneNode(true);
    clonedElement.style.display = 'block';
    clonedElement.style.outline = 'rgba(0, 0, 0, .25) solid 50vmax';
    clonedElement.style.height = '100px';
    clonedElement.style.width = '100px';
    clonedElement.style.removeProperty('top');
    clonedElement.style.removeProperty('left');
    element.parentNode.replaceChild(clonedElement, element);
  }
  /* istanbul ignore else */
  if (videoElement) {
    videoElement.style.overflow = 'hidden';
  }
};
