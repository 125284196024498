import React from 'react';
import classNames from 'classnames/bind';
import { bool, func, shape } from 'prop-types';
import {
  Drawer,
  DrawerItem, DrawerHeader
} from '@thd-olt-component-react/drawer';
import styles from './scan-another-ticket-drawer.module.scss';

const cx = classNames.bind(styles);

export const ScanAnotherTicketDrawer = (props) => {
  const { open, onClose, scanButtonRef, videoRef, } = props;

  return (
    <div className={cx('scan-ticket-drawer')}>
      <Drawer
        open={open}
        onClose={onClose}
        initialItem="scan-ticket-drawer"
        position="right"
      >
        <DrawerItem name="scan-ticket-drawer">
          <DrawerHeader>
            <span className={cx('scan-ticket-title')}>SCAN TICKETS</span>
          </DrawerHeader>
          <div
            data-testid="digital-ticket-qr-container"
            id="digital-ticket-video"
            className={cx('digital-ticket-qr-container')}
            ref={scanButtonRef}
          >
            <video ref={videoRef} />
          </div>
        </DrawerItem>
      </Drawer>
    </div>
  );
};

ScanAnotherTicketDrawer.propTypes = {
  open: bool,
  onClose: func.isRequired,
  scanButtonRef: shape({}).isRequired,
  videoRef: shape({}).isRequired
};

ScanAnotherTicketDrawer.defaultProps = {
  open: false,
};
