import React, { useRef, useEffect } from 'react';
import { string, shape } from 'prop-types';
import JsBarcode from 'jsbarcode';
import classNames from 'classnames/bind';

import styles from './digital-ticket-barcode.module.scss';

const cx = classNames.bind(styles);

export const Barcode = (props) => {
  const { text, options } = props;

  const containerRef = useRef(null);

  useEffect(() => {
    if (text) {
      JsBarcode(containerRef.current, text, { ...options });
    }
  }, [text]);

  if (!text) {
    return null;
  }

  return (
    <div className={cx('barcode-container')} data-testid="barcode-container">
      <img className={cx('barcode')} ref={containerRef} alt="barcode" width="100%" height="100%" />
    </div>
  );
};

Barcode.displayName = 'DigitalTicketBarcode';

Barcode.propTypes = {
  text: string.isRequired,
  options: shape({})
};

Barcode.defaultProps = {
  options: {}
};
