import React from 'react';
import PropTypes from 'prop-types';
import { Close } from '@one-thd/sui-icons';
import { Link, Button } from '@one-thd/sui-atomic-components';
import {
  useDataModel, params, string, shape
} from '@thd-nucleus/data-sources';

export const ProductRemove = ({ itemId, onRemove, text, link }) => {

  const { data } = useDataModel('product', {
    variables: {
      itemId
    }
  });

  const { product } = data || {};

  if (product?.info?.productSubType?.name === 'HDQC') {
    return null;
  }

  return (
    <>
      {link ? <Link href="#0" onClick={() => { onRemove(itemId); }} underline="always">{text}</Link>
        : (
          <Button
            variant="secondary"
            data-testid="product-remove-action"
            fullWidth
            startIcon={Close}
            onClick={() => { onRemove(itemId); }}
          >
            {text}
          </Button>
        )}
    </>
  );
};

ProductRemove.displayName = 'ProductRemove';

ProductRemove.dataModel = {
  product: params({
    itemId: string().isRequired()
  }).shape({
    itemId: string(),
    dataSources: string(),
    info: shape({
      productSubType: shape({
        name: string()
      })
    })
  })
};

ProductRemove.propTypes = {
  itemId: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  text: PropTypes.string,
  link: PropTypes.bool,
};

ProductRemove.defaultProps = {
  onRemove: () => {},
  text: 'Remove',
  link: false
};